import React from 'react';

import Profile from "./profile"
import matt from './images/matt.jpg';
import oy from './images/oy.jpg';
import ab from './images/ab.jpg'
export const profiles = [
    {
        name: "Matthew Hessaa-Szwinto",
        description: "Has a passion for programming and problem solving. He has 5 years experience running his own business and a unique devotion to self learning. His ideas have helped improve and optimise both traditional trading systems and develop new effective trading strategies.",
        image: matt,
        key: "prof-m"
    },
    {
        name: "Øystein Saveland",
        description: "Has a multidisciplinary backrground with a masters degree in engineering from NTNU, in addition to an economical background from UiA. He's been working as an analyst in the Oil and gas industry the past ten years - but has also been developing automated trading systems for five years.",
        image: oy,
        key: "prof-o"
    },
    {
        name: "Abbas Sadiq",
        description: "Holds a masters degree in Networking & IT Security from Glamorgan university Cardiff, and has 14 years of experience from oil and gas industry, in addition to six years of experience in developing trading algorithms. His passion is trading and statistics.",
        image: ab,
        key: "prof-a"
    }
]
export default function Profiles() {
    return (<div className="profiles container">
        {profiles.map(profile => <Profile {...profile} />)}

    </div>);
}