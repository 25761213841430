import React, { useState } from 'react';
import Helmet from 'react-helmet'
import { faEdit, faEnvelope, faMobileAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import Form, { validateEmail, validateEmpty, validatePhone } from './form';
import { IGEndpoint } from '../../services/api';

export default function Contact() {
    const formUrl = IGEndpoint + "/sendmail";
    const [form, updateForm] = useState({
        name: {
            value: "",
            type: "text",

            validator: validateEmpty,
            placeholder: 'Name',
            icon: faUser,
        },
        email: {
            value: "",
            type: "text",
            validator: validateEmail,
            placeholder: 'Email',
            icon: faEnvelope
        },
        mobile: {
            value: "",
            type: "text",
            validator: validatePhone,
            placeholder: 'Phone',
            icon: faMobileAlt
        },
        message: {
            value: "",
            type: "textarea",
            validator: validateEmpty,
            placeholder: 'Message',
            icon: faEdit
        }
    });


    return (<div className="contact">
        <Helmet>
            <title>Algotrade - Ask a question</title>
            <link rel="canonical" href={`https://algotrade.org/contact`} />

            <meta property="og:title" content="Algotrade - Contact" />
            <meta property="og:description" content="Get in touch" />
            <meta property="og:url" content={`https://algotrade.org/contact`} />

            <meta name="twitter:title" content="Algotrade - Contact" />
            <meta name="twitter:description" content="Get in touch" />

            {/* <meta property="og:image" content={this.state.article.featuredImage.formats.medium.url} />
                <meta name="twitter:image" content={this.state.article.featuredImage.formats.medium.url} /> */}

        </Helmet>

        <div className="app-header">
            <h1>Ask a question</h1>
        </div>


        <section className="container contact-container">

            <div>
                <h2>Get in touch</h2>
                <p>For general inquiries, please use the contact form below.</p>
                <p>Due to high demand, our current response rate is 1-2 days. For urgent inquiries please email <a href="mailto:post@algotrade.org">post@algotrade.org</a>, with the subject "Urgent"</p>
            </div>


            <Form form={form} updateForm={updateForm} url={formUrl} />
        </section>

    </div>

    )
}