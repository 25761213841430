import React from 'react';
export default function Sidebar({ children }) {
    return (
        <div className="app-sidebar">
            <div className="sidebar-container">
                {children}
            </div>

        </div>
    )
}