import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { parsePrice } from '../../services/helpers';
import Info from '../info';
import dd from '../../images/dd.jpeg';
dayjs.extend(customParseFormat)

export function Drawdown(capital, data) {
    return data.reduce((result, current) => {
        let currentProfit = parsePrice(current.profitAndLoss);
        result.total += currentProfit;
        result.currentDrawdown = ((result.currentHigh - result.total) / result.currentHigh) * 100

        //If its the first iteration, or we have come back to the current high.
        if (result.total > result.currentHigh) {
            result.currentHigh = result.total;
            result.currentLow = result.total;
            return result;
        }
        // Set the current low
        result.currentLow = Math.min(result.total, result.currentLow)
        result.maxDrawdown = Math.max(result.currentDrawdown, result.maxDrawdown);

        return result;
    }, { maxDrawdown: 0, currentLow: capital, currentHigh: capital, total: capital, currentDrawdown: 0 });
}

export default function MaxDrawdown({ historicData, capital }) {
    if (!historicData || historicData.length === 0) {
        return null;
    }
    const { maxDrawdown } = Drawdown(capital, historicData);
    return (
        <div className="chart-box net-profit bordered">
            <span className="number">{maxDrawdown.toFixed(1)}%</span>
            <span>Max Drawdown </span>
            <Info>
                <h3>Max Drawdown</h3>
                <p>is the % difference between a high in the equity curve and the lowest low.</p>
                <img src={dd} alt="chart showing max drawdown" />
            </Info>
        </div>
    )
}