import { faInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { getScrollingElement } from '../services/helpers';
export default function Info({ children }) {
    const [isOpen, setOpen] = useState(false);
    const iconRef = useRef();


    useEffect(() => {
        const close = (e) => {
            if (e.target !== iconRef.current) {
                setOpen(false)
            }

        };
        const scrollingElement = getScrollingElement();
        if (isOpen) {
            scrollingElement.style.overflowY = 'hidden';
            window.addEventListener('click', close);
        } else {
            scrollingElement.style.overflowY = 'auto';
            window.removeEventListener('click', close);

        }
        return () => {
            window.removeEventListener('click', close);

        }
    }, [isOpen, iconRef]);


    return (<>
        <FontAwesomeIcon forwardedRef={iconRef} className="info" onClick={() => setOpen(!isOpen)} icon={faInfo}></FontAwesomeIcon>
        <CSSTransition classNames="info-animation" in={isOpen} timeout={300} mountOnEnter unmountOnExit>
            <div className="info-text">
                <div className="info-close"> <FontAwesomeIcon onClick={() => setOpen(false)} icon={faTimes}></FontAwesomeIcon></div>
                {children}
            </div>
        </CSSTransition>

    </>
    )
}