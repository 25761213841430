import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { io } from "socket.io-client";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import LoadingLogo from '../loadingLogo';
import Collapse from '../accordian/collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import { epicIndex } from './closedTrades';

dayjs.extend(isSameOrAfter)

const socket = io();
let prevPositionsLength = 0

export default function LiveTrades({ callUpdate }) {

    const [positions, updatePositions] = useState([])
    const [loading, setLoading] = useState(true);

    // Function to get a new list of closed positions if the current live trades amount has changed
    useEffect(() => {
        if (positions && prevPositionsLength !== Object.values(positions).length) {
            callUpdate();
        }
        prevPositionsLength = Object.values(positions)?.length || 0
    }, [positions, callUpdate])

    useEffect(() => {
        const resetPositions = () => {
            updatePositions([]);
        }
        // const closedUpdate = (dealId) => {
        //     let newPositions = positions.filter(pos => pos.dealId !== dealId);
        //     updatePositions(newPositions)
        // };
        const streamUpdate = (msg) => {
            setLoading(false);
            updatePositions(msg)
        }

        const cleanup = () => {
            socket.off('trade-stream-update', streamUpdate);
            socket.off("disconnect", resetPositions);
            // socket.off('position-closed', closedUpdate);

        }
        const init = () => {
            socket.on('trade-stream-update', streamUpdate);
            socket.on("disconnect", resetPositions);
            // socket.on('position-closed', closedUpdate);


        }

        const onIoOnOff = () => {
            if (document.hidden) {
                cleanup();
            } else {
                setLoading(true);
                init();


            }
        }
        document.addEventListener("visibilitychange", onIoOnOff);
        init();
        return () => {
            cleanup()

            document.removeEventListener("visibilitychange", onIoOnOff);

        }
    }, [])

    // useEffect(() => {

    //     socket.on('trade-stream-update', streamUpdate);

    //     socket.on('position-closed', closedUpdate);

    //     socket.on("disconnect", resetPositions);

    //     // Specify how to clean up after this effect:
    //     return;
    // }, [streamUpdate, closedUpdate]);

    if (Object.values(positions).length === 0) {
        return null;
    }

    let liveTotal = 0;

    let agregated = Object.values(positions).reduce((result, item) => {
        if (!result[item.epic]) {
            result[item.epic] = { items: [item], ...item };
        } else {
            const prevPerc = 100 + result[item.epic].changeFromOpenPercent;
            const nextPerc = 100 + item.changeFromOpenPercent;
            const res = ((prevPerc * nextPerc) / 100) - 100;
            result[item.epic].changeFromOpenPercent = res;
            result[item.epic].items.push(item);
        }
        return result;
    }, {})

    return (<div className="chart-box live-trades bordered">

        <h4>Open Trades</h4>

        {agregated !== {} &&
            <ul className="live">
                {Object.values(agregated).map((value) => {
                    if (!value)
                        return null;
                    liveTotal += value.changeFromOpenPercent;
                    let className = value.changeFromOpenPercent.toFixed(2) >= 0 ? 'green' : 'red';
                    const title = <>
                        <span> {value.items.length > 1 && <FontAwesomeIcon icon={faListOl} />} {epicIndex[value.epic] || value.epic}</span>
                        <span className={className}> {value.changeFromOpenPercent?.toFixed(2)}%</span>
                    </>;

                    return (<li key={'live-' + value.epic}>
                        {value.items.length > 1 ?
                            <Collapse title={title}
                                hideToggle={true}
                                content={() => {
                                    let className = value.changeFromOpenPercent.toFixed(2) >= 0 ? 'green' : 'red';

                                    return (<ul>
                                        {value.items.map((subItem, index) => <li><span>{index + 1}</span><span className={className}>{subItem.changeFromOpenPercent?.toFixed(2)}%</span></li>)}
                                    </ul>)
                                }
                                }
                            />
                            : title
                        }



                    </li>)
                })
                }

                <li className="line-above" style={{ flex: '1 0 100%' }}>
                    {Object.values(agregated).length === 0 ?

                        <span>There is currently no live trades</span>
                        :
                        <>

                            <span>TOTAL</span>
                            <span className={liveTotal.toFixed(2) >= 0 ? 'green' : 'red'}>{liveTotal.toFixed(2)}%</span>
                        </>}
                </li>
            </ul>
        }

        {loading && <LoadingLogo />}
    </div>)
}
