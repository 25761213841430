import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet'
import { getFaq } from '../../services/api';
import Accordian from '../../components/accordian/accordian';
import { Link } from 'react-router-dom';
export default function Faq() {
    const [faqs, setFaqs] = useState([]);
    const [filtered, filter] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const getFaqs = async () => {
            const response = await getFaq()
            setFaqs(response);
        }

        getFaqs();
    }, [])

    const onChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

    }

    useEffect(() => {
        const stringafyFaqs = () => {
            return faqs?.map((faq) => JSON.stringify(faq))
        }

        if (!faqs) {
            return;
        }
        if (!searchTerm || searchTerm === "") {
            filter(faqs)
        }

        const results = stringafyFaqs()?.filter(faq => faq.toLowerCase().includes(searchTerm.toLowerCase()));
        results && results.length > 0 ? filter(results.map(res => JSON.parse(res))) : filter([]);
    }, [searchTerm, faqs])


    const toShow = filtered || faqs;

    return (
        <div className="faq">
            <Helmet>
                <title>Algotrade - Nice to know</title>
                <link rel="canonical" href={`https://algotrade.org/faq`} />

                <meta property="og:title" content="Algotrade - Faq" />
                <meta property="og:description" content="Frequently asked questions" />
                <meta property="og:url" content={`https://algotrade.org/faq`} />

                <meta name="twitter:title" content="Algotrade - Faq" />
                <meta name="twitter:description" content="Frequently asked questions" />

                {/* <meta property="og:image" content={this.state.article.featuredImage.formats.medium.url} />
                <meta name="twitter:image" content={this.state.article.featuredImage.formats.medium.url} /> */}

            </Helmet>

            <div className="app-header">
                <h1>Nice to know</h1>
            </div>

            <div className="container">
                <p>This is our FAQ, if you can't find what you're looking for then send us a message using our contact form. <Link to="/ask-a-question">Here</Link></p>

                <div className="search">
                    <label>Search</label>
                    <input onChange={onChange} placehoder="Search FAQ" value={searchTerm} />
                </div>

            </div>



            {(!filtered || filtered.length === 0) && <div className="container">
                <h2>Submit a question</h2>
                <p>Cant't find what your're looking for, ask us a question.</p>
                <Link to="/contact" className="button">Contact Us</Link>
            </div>}

            <div className="container">
                <Accordian items={toShow} />


            </div>

        </div>
    )
}