import { Line } from 'react-chartjs-2'
import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { parsePrice } from '../../services/helpers';

dayjs.extend(customParseFormat)


export default function ProfitAndLoss({ historicData, capital, refData, refLable }) {


    let startCapital = capital;
    let totalPercent = 0;
    const data = {

        datasets: [
            {
                label: 'Algotrade',
                data: historicData.filter(tran => tran.transactionType === 'HANDEL').reduce((result, item) => {
                    const pAndL = parsePrice(item.profitAndLoss);
                    startCapital = startCapital + pAndL

                    const pAndLPercent = (pAndL / capital) * 100
                    totalPercent += pAndLPercent;
                    let currentItem = result.find(i => i.x === dayjs(item.date, "DD/MM/YY").valueOf());
                    if (!currentItem) {
                        result.push({
                            x: dayjs(item.date, "DD/MM/YY").valueOf(),
                            y: totalPercent
                        })
                    }
                    else {
                        currentItem.y += pAndLPercent;
                    }
                    // (item / 100) * exposure * capital
                    return result;
                }, []),
                fill: false,
                backgroundColor: 'rgb(255, 255, 132)',
                borderColor: 'rgba(255, 255, 132, 1)',
                pointRadius: 0.5,
                borderWidth: 2,

            },

        ],
    }


    if (refData) {
        let startCapitalOslo = refData[0]?.openPrice?.bid || 0;

        data.datasets.push({
            label: refLable,
            data: refData?.reduce((result, price) => {
                let osloPercent = ((price.openPrice.bid - startCapitalOslo) / startCapitalOslo) * 100

                result.push({
                    x: price.date.valueOf(),
                    y: osloPercent
                })
                // (item / 100) * exposure * capital
                return result;

            }, []),
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 1)',
            pointRadius: 0.5,
            borderWidth: 2,
        });
    }




    const options = {
        responsive: true,
        elements: {
            line: {
                tension: 0
            }
        },
        legend: {
            display: true,
            labels: {
                color: 'white'
            }


        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: false,
                    callback: function (value, index, values) {
                        return value + '%';
                    }
                }
            }],
            xAxes: [{
                type: 'time',
                time: {
                    unit: 'quarter'
                },
                ticks: {
                    autoSkip: true,
                    source: 'auto'
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';

                    if (label) {
                        label += ': ';
                    }
                    label += Math.round(tooltipItem.yLabel * 100) / 100;
                    return label;
                }
            }
        }
    }
    return (
        <div className="pl-line bordered">
            <Line data={data} options={options} />

        </div>
    )
}