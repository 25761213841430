import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { parsePrice } from '../../services/helpers';

dayjs.extend(customParseFormat)


export default function NetProfit({ historicData, capital }) {

    if (!historicData || historicData.length === 0) {
        return null;
    }
    let total = 0
    historicData.forEach(trade => {

        const tradeResult = parsePrice(trade.profitAndLoss);
        total += tradeResult;

    });

    return (
        <div className="chart-box net-profit bordered">
            <span className="number">{(((total / capital) * 100)).toFixed(1)}%</span>
            <span>Net Profit</span>
        </div>
    )
}