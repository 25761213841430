import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamation, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import LoadingLogo from '../../components/loadingLogo';

export function validateEmail(value) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return !re.test(value) && 'Email is invalid';
};

export function validateEmpty(value) {
    return !value && 'Field is empty';
}
export function validatePhone(value) {
    let phoneFormat = /^[0-9]{0,}$/;
    return !phoneFormat.test(value) && 'Mobile is invalid';
}
export default function Form({ form, updateForm, hiddenFields, url }) {

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState();

    const [formError, setError] = useState();
    const hasError = () => {
        let state = {
            ...form
        };
        let error = false;
        Object.entries(form).forEach(([key, field]) => {
            let fieldError = field.validator(field.value);
            if (fieldError) {
                error = true;

            }
            state[key].error = fieldError
        })

        updateForm(state)

        return error;

    }
    const submitForm = async (e) => {
        e.preventDefault();
        if (hasError()) {
            return;
        }
        if (response) {
            setResponse(false);
        }
        setLoading(true)
        try {
            let { name, email, mobile, message } = form;
            let request = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                method: 'POST',
                body: JSON.stringify({ name: name.value, email: email.value, mobile: mobile.value, message: message.value, ...hiddenFields })
            }).catch(() => {
                setLoading(false);
                return setError("Something went wrong, please refresh and try again")
            });
            let response = await request?.json();

            if (!request || request.status === 404 || request.status === 500 || response.status === 'error') {
                return setError("Something went wrong, please refresh and try again")

            }


            setLoading(false)
            setResponse(response);
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
                return setError("Something went wrong, please refresh and try again")

            }, 2000)

        }

    }

    const onChange = (e) => {
        let state = {
            ...form
        };
        setError(false)
        state[e.target.name].value = e.target.value;
        state[e.target.name].error = form[e.target.name].validator(e.target.value);
        updateForm(state)
    }

    let submitClass = loading ? 'sending' : 'ready';
    let disabled = loading || formError;

    if (!url) {
        return null
    }
    return (



        <div className="form-container">

            <form onSubmit={submitForm}>

                {Object.entries(form).map(([key, field]) => {

                    if (field.type === 'textarea') {

                        return (<div className={"input-with-icon text-area" + (field.error ? ' error' : '')}>
                            <textarea name={key} placeholder={field.placeholder} value={field.value} onChange={onChange} />
                            <FontAwesomeIcon icon={field.icon} />
                            <p className="error">{field.error}</p>
                        </div>);
                    }


                    return (<div className={"input-with-icon" + (field.error ? ' error' : '')}>
                        <input type="text" name={key} placeholder={field.placeholder} value={field.value} onChange={onChange} />
                        <FontAwesomeIcon icon={field.icon} />
                        <p className="error">{field.error}</p>
                    </div>)
                }
                )}

                {loading && <LoadingLogo />}
                <div className="errors">
                    {formError && <p>{formError}</p>}
                </div>
                {response && <div>{response.message}</div>}
                <button disabled={disabled} className={submitClass} type="submit">Send
                    {response ? <FontAwesomeIcon icon={faCheckCircle} /> :
                        formError ? <FontAwesomeIcon icon={faExclamation} /> : <FontAwesomeIcon icon={faPaperPlane} className="plane" />}
                </button>
            </form>
        </div>



    )
}