import React, { useEffect, useState } from 'react';



export const MediaQueryContext = React.createContext();
export const isDesktop = () => {
    return window.matchMedia('(min-width: 1201px)').matches;
}

export const isMobile = () => {
    return window.matchMedia('(max-width: 600px)').matches;
}

export const isTablet = () => {
    return window.matchMedia('(min-width: 601px) and (max-width: 1200px)').matches;
}

export const isHandheld = () => {
    return window.matchMedia('(max-width: 1200px)').matches;
}

export const MediaQueryProvider = ({ children }) => {
    const [query, setQuery] = useState();




    useEffect(() => {
        const listener = () => {
            const newQuery = isDesktop() ? 'desktop' : isTablet() ? 'tablet' : 'mobile';
            if (newQuery !== query) {
                setQuery(newQuery);
            }
        };

        window.addEventListener('resize', listener)
        listener()
        return () => window.removeEventListener('resize', listener)

    }, [query]);


    return (
        <MediaQueryContext.Provider value={{ query }} >
            {children}
        </MediaQueryContext.Provider>
    );
};
