import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { getHistoricData, getPriceData } from '../../services/api';
import ProfitAndLoss from './profitAndLoss';
import MonthByMonth from './monthByMonth';
import NetProfit from './netProfit';
import Winrate from './winrate';
import NumberOfTrades from './numberOfTrades';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import ProfitAndLossBar from './profitAndLossBar';
import { parsePrice } from '../../services/helpers';
import AnualisedProfit from './anualisedProfit';
import TradesPerDay from './tradesPerDay';
import LiveTrades from '../liveTrades/liveTrades';
import LoadingLogo from '../loadingLogo';
import { MediaQueryContext } from '../../services/mediaQueryContext';
import MaxDrawdown from './maxDrawdown';
import CurrentDrawdown from './currentDrawdown';
import NPDD from './npDD';
import ClosedTrades from '../liveTrades/closedTrades';

// export const epicIndex = {
//     "DAX": "Germany 30 Cash",
//     "WS": "Wall Street Cash",
//             "US Tech": "",
//     "FTSE": "FTSE 100 Cash",
//             "EUR/USD": "",
//             "GBP/USD": "",
//             "GBP/EUR": "",
//             "US 500 Cash"
// }
const priceEpicsIndex = {
    "Oslo Børs": 'IX.D.OBX.IFD.IP',
    "NASDAQ": "IX.D.NASDAQ.IFE.IP",
    "FTSE 100": "IX.D.FTSE.IFE.IP",
    "DAX": "IX.D.DAX.IFMM.IP",
    "Dow Jones": "IX.D.DOW.IFE.IP",
    "FR 40": "IX.D.CAC.IMF.IP",
    "S&P 500": "IX.D.SPTRD.IFE.IP",
    "US Crud": "CC.D.CL.UME.IP",
}

const priceEpicsIndexInverted = {};
Object.entries(priceEpicsIndex).forEach(([k, v]) => { priceEpicsIndexInverted[v] = k });



export default function Charts() {
    const [historicData, setHistoricData] = useState(null);
    const [refData, setRefData] = useState([]);
    const [hasErrored, setError] = useState(false);
    const [tradeType, setType] = useState('All');
    const [instrument, setInstrument] = useState('All');
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year').startOf('day').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const mediaQuery = useContext(MediaQueryContext).query;
    const [showChartFilters, setShowChartFilters] = useState(mediaQuery !== 'mobile');
    const [period, setPeriod] = useState(null);
    const [refEpic, setRefEpic] = useState(priceEpicsIndex["S&P 500"]);

    if (period == null) {
        setPeriod('All Time');
    }
    // const addFilter = (callback) => {
    //     const joined = filters.concat(callback);
    //     setFilters(joined);
    // }

    // const removeFilter = (callback) => {
    //     const filtered = filters.filter(filter => filter !== callback);
    //     setFilters(filtered);
    // }


    const getData = async () => {
        const data = await getHistoricData().catch(e => { console.error(e); setError(true) });
        if (!data || data.length === 0) {
            return setError(true);
        }
        setHistoricData(data);
        setStartDate(data[0].date);
    }

    const getRefData = async () => {
        if (refEpic === "") {
            setRefData([]);

            return;
        }
        const data = await getPriceData(refEpic);

        setRefData(data);
    }

    useEffect(() => {
        if (instrument === "Germany") {
            setRefEpic(priceEpicsIndex.DAX);
        } else if (instrument === "France") {
            setRefEpic(priceEpicsIndex["FR 40"]);
        } else if (instrument === "Wall Street") {
            setRefEpic(priceEpicsIndex.DOW);
        } else if (instrument === "US Tech") {
            setRefEpic(priceEpicsIndex.NASDAQ);
        }
        else if (instrument === "US Crude") {
            setRefEpic(priceEpicsIndex["US Crud"]);
        } else if (instrument === "All") {
            setRefEpic(priceEpicsIndex['S&P 500']);
        }
            else if (instrument === "US 500") {
                setRefEpic(priceEpicsIndex['S&P 500']);
            
        } else if (priceEpicsIndex[instrument] !== undefined) {
            setRefEpic(priceEpicsIndex[instrument]);
        } else {
            setRefEpic("");
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
        };
    }, [instrument]);

    useEffect(() => {

        getData();
        getRefData();


        // Specify how to clean up after this effect:
        return function cleanup() {
        };
    }, []);

    useEffect(() => {

        if (refEpic == null) {
            setRefData([]);
            return;
        }
        getRefData();

    }, [refEpic]);

    if (hasErrored) {
        return <div className='container'><p>Could not load chart data at this time. The system may be down for maintenance. Please try back again later.</p>
            <button onClick={() => window.location.reload()}>Refresh</button></div>
    }

    if (historicData === null || refData === null) {
        return (<div className="charts"><LoadingLogo size={20} /></div>);
    }

    const startDatefilter = (item) => {
        const date = dayjs(startDate);
        return item.date.isSameOrAfter(date);
    }

    const endDatefilter = (item) => {
        const date = dayjs(endDate);
        return item.date.isSameOrBefore(date);
    }

    const longShortFilter = (item) => {
        if (tradeType === 'Long') {
            return item.size > 0;
        }
        if (tradeType === 'Short') {
            return item.size < 0
        }
        return true
    }

    const instrumentFilter = (item) => {
        if (instrument === "All") {
            return true;
        }
        return item.instrumentName.includes(instrument);

    }


    const handlePeriodChange = (e) => {
        let end = dayjs().endOf('day');
        let start;
        switch (e.target.value) {
            case "This Month": start = dayjs().startOf('month');
                break;
            case "Last Month":
                start = dayjs().subtract(1, 'months').startOf('month');
                end = start.endOf('month');
                break;
            case "3 Months": start = end.subtract(3, 'months');
                break;
            case "6 Months": start = end.subtract(6, 'months');
                break;
            case "9 Months": start = end.subtract(9, 'months');
                break;
            case "1 Year": start = end.subtract(1, 'year');
                break;
            case "YTD": start = end.startOf('year');
                break;
            case "All Time": start = historicData[0].date;
                break;
            default: start = dayjs(startDate);
                break;
        }

        setStartDate(start.toDate());
        setEndDate(end.toDate());
        setPeriod(e.target.value)
    }

    const filteredData = historicData
        ?.filter(startDatefilter)
        ?.filter(endDatefilter)
        ?.filter(instrumentFilter)
        ?.filter(longShortFilter);




    const filteredRefData = refData
        ?.filter(startDatefilter)
        ?.filter(endDatefilter);

    if (!filteredData || filteredData.length === 0) {
        if (instrument !== "All") {
            setInstrument("All")
            alert("No Data available for that Instrument, setting to All");
            return
        }
        if (refEpic !== "S&P 500") {
            setRefEpic(priceEpicsIndex["S&P 500"]);
            alert("No Reference Data available for that instrument, setting to S&P 500");
            return

        }
        if (period !== "All Time") {
            setPeriod("All Time");
            alert("No Data available for that date range, setting to All time");
            return

        }

        return
    }

    if (instrument !== "All") {
        const firstRefDate = dayjs(filteredData[0]?.date);
        if (!firstRefDate.isSame(dayjs(startDate))) {
            setStartDate(firstRefDate.toDate());
            setPeriod("Custom")
        }
    }

    const startItem = filteredData[0];
    const startCapital = startItem?.runningCapital - parsePrice(startItem.profitAndLoss)

    const range = dayjs(endDate).diff(startDate, 'months');
    const barUnit = range >= 12 ? 'quarter' : range > 3 ? 'month' : range > 1 ? 'week' : 'day';
    const monthClick = (key) => {
        const [clickedYear, clickedmonth] = key.split('-');
        setStartDate(dayjs().year(clickedYear).month(clickedmonth).startOf('month').toDate())
        setEndDate(dayjs().year(clickedYear).month(clickedmonth).endOf('month').toDate())
        setPeriod('Custom')

    }
    const yearClick = (key) => {
        const [clickedYear] = key.split('-');
        setStartDate(dayjs().year(clickedYear).startOf('year').toDate())
        setEndDate(dayjs().year(clickedYear).endOf('year').toDate())
        setPeriod('Custom')
    }
    return (<div className="charts">



        <div className="chart-filters" style={{ maxHeight: showChartFilters ? '500px' : '73px' }}>
            <div className="filters-container">
                {mediaQuery === 'mobile' && <div className="filter-icon" onClick={() => setShowChartFilters(!showChartFilters)}><h2>Filters</h2><FontAwesomeIcon className={showChartFilters ? 'showing' : 'hidden'} icon={faFilter} /></div>}

                {showChartFilters && <>
                    <div className="chart-filter">
                        <label>Period</label>
                        <select value={period} onChange={handlePeriodChange}>
                            <option>This Month</option>
                            <option>Last Month</option>
                            <option>3 Months</option>
                            <option>6 Months</option>
                            <option>9 Months</option>
                            <option>1 Year</option>
                            <option>YTD</option>
                            <option>All Time</option>
                            <option>Custom</option>

                        </select>
                    </div>
                    {period === 'Custom' && <div className="chart-custom-dates">
                        <div className="chart-filter date">
                            <DatePicker
                                selected={startDate}
                                onSelect={setStartDate} //when day is clicked
                                onChange={setStartDate} //only when value has changed
                                dateFormat="dd.MM.yyyy"

                            />
                        </div>
                        <div className="chart-filter date">
                            <DatePicker
                                selected={endDate}
                                onSelect={setEndDate} //when day is clicked
                                onChange={setEndDate} //only when value has changed
                                dateFormat="dd.MM.yyyy"

                            />
                        </div>
                    </div>
                    }

                    <div className="chart-filter">
                        <label>Type of Trade</label>
                        <select onChange={(e) => {
                            setType(e.target.value)
                        }}>
                            <option>All</option>
                            <option>Long</option>
                            <option>Short</option>
                        </select>
                    </div>

                    <div className="chart-filter">
                        <label>Instrument</label>
                        <select value={instrument} onChange={(e) => setInstrument(e.target.value)}>
                            <option>All</option>
                            <option value="Germany">Dax 40</option>
                            <option value="France">FR 40</option>
                            <option>FTSE 100</option>
                            <option value="US 500">S&P 500</option>
                            <option value="US Tech">Nasdaq</option>
                            <option value="Wall Street">Dow Jones</option>
                            <option value="Us Crude">US Crude</option>
                            <option>AUD/USD</option>
                            <option>EUR/USD</option>
                            <option>GBP/USD</option>
                            <option>USD/JPY</option>
                            <option>GBP/JPY</option>
                            <option>EUR/JPY</option>
                            <option>CAD/USD</option>
                        </select>
                    </div>

                    <div className="chart-filter">
                        <label>Comparison</label>
                        <select value={refEpic} onChange={(e) => {
                            setRefEpic(e.target.value);
                        }}>
                            <option>None</option>

                            {
                                Object.entries(priceEpicsIndex).map(([key, value]) => {

                                    return <option value={value}>{key}</option>
                                })
                            }

                        </select>
                    </div>

                </>
                }

            </div>
        </div>

        <div className="container full charts-outer-container">
            <div className="charts-container">
                <ProfitAndLoss capital={startCapital} historicData={filteredData} refLable={priceEpicsIndexInverted[refEpic]} refData={filteredRefData} />


                <ProfitAndLossBar capital={startCapital} historicData={filteredData} unit={barUnit} />


                <NetProfit historicData={filteredData} capital={startCapital} />
                <MaxDrawdown historicData={filteredData} capital={startCapital} />

                <MonthByMonth historicData={historicData} onMonthClick={monthClick} onYearClick={yearClick} />

                <TradesPerDay historicData={filteredData} />
                <NumberOfTrades historicData={filteredData} />
                <AnualisedProfit filteredData={filteredData} capital={startCapital} />
                <Winrate historicData={filteredData} />

                {dayjs(endDate).startOf('day').isSame(dayjs().startOf('day')) && <CurrentDrawdown historicData={filteredData} capital={startCapital} />}
                <NPDD historicData={filteredData} capital={startCapital} />
            </div>

            <div className="live-trades-closed-trades">

                <LiveTrades callUpdate={() => getData()} historicData={historicData} />
                <ClosedTrades callUpdate={() => getData()} historicData={historicData} />
            </div>
        </div>

    </div>)
}
