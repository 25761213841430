import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)


export default function TradesPerDay({ historicData }) {
    if (!historicData || historicData.length === 0) {
        return null;
    }
    const total = historicData.filter(tran => tran.transactionType === 'HANDEL').length;
    const first = historicData[0]?.date;
    const last = historicData[historicData.length - 1]?.date;
    const difference = last.diff(first, 'days');
    const tpd = total / difference;
    return (
        <div className="chart-box net-profit bordered">
            <span className="number">{tpd.toFixed(2)}</span>
            <span>Trades Per Day</span>
        </div>
    )
}