import React, { useEffect, useRef, useState, } from 'react';
import Helmet from 'react-helmet';
import { getHistoricData, strapiEndpoint } from '../../services/api';
import { parsePrice } from '../../services/helpers';
import dayjs from 'dayjs';
import RangeSlider from '../../components/rangeSlider/rangeSlider';
import Form, { validateEmail, validateEmpty, validatePhone } from '../contact/form';
import { faEdit, faEnvelope, faMobileAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import {
    Link,
    Route,
    useLocation
} from "react-router-dom";

//converts 4000000 into 4,000,000
export function formatMoney(number) {
    let result = '';
    let flagDecimal = false;
    let arr = number.toString().split('');
    arr.forEach((digit, index) => {
        const left = arr.length - index - 1;
        if (digit === '.') {
            flagDecimal = true;
        }
        if (flagDecimal) {
            return digit
        }
        if (left % 3 === 2 && index !== 0) {
            result += ','
        }
        result += digit;
    });
    return result
}

export default function Invest() {
    const formUrl = strapiEndpoint + "/sendmail"

    let location = useLocation();
    const maxCapital = 10000000;
    const [capital, setCapital] = useState(400000 / 1);
    const [exposure, setExposure] = useState(1);
    const [historicData, setHistoricData] = useState(null);
    const getData = async () => {
        setHistoricData([]);
        const data = await getHistoricData().catch(e => { console.log(e); });

        setHistoricData(data);
    }
    const minCapital = 400000 / parseFloat(exposure);
    const getStarted = useRef();
    const [form, updateForm] = useState({
        name: {
            value: "",
            type: "text",

            validator: validateEmpty,
            placeholder: 'Name',
            icon: faUser,
        },
        email: {
            value: "",
            type: "text",
            validator: validateEmail,
            placeholder: 'Email',
            icon: faEnvelope
        },
        mobile: {
            value: "",
            type: "text",
            validator: validatePhone,
            placeholder: 'Phone',
            icon: faMobileAlt
        },
        message: {
            value: "",
            type: "textarea",
            validator: validateEmpty,
            placeholder: 'Message',
            icon: faEdit
        }
    });

    useEffect(() => {

        getData();

        // Specify how to clean up after this effect:
        return function cleanup() {
        };
    }, []);

    useEffect(() => {
        if (location.pathname === '/invest/get-started') {
            window.scrollTo(0, getStarted?.current?.getBoundingClientRect().top - 30)
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
        };
    }, [location]);


    if (!historicData) {
        return <p>...Loading</p>
    }

    const lastSix = historicData.filter(item => item.date.isAfter(dayjs().subtract(6, 'months')));
    const startCapital = historicData.filter(item => item.date.isBefore(dayjs().subtract(6, 'months'))).reduce((result, item) => {
        result += parsePrice(item.profitAndLoss);
        return result;
    }, 0);

    const netProfit = lastSix.reduce((result, item) => {
        result += parsePrice(item.profitAndLoss);
        return result;
    }, 0)

    const percentProfit = (netProfit / startCapital) * 100;
    const predictedProfit = ((capital / 100) * percentProfit * exposure).toFixed();
    return (
        <div className="invest">
            <Helmet>
                <title>Algotrade - Invest</title>
                <link rel="canonical" href={`https://algotrade.org/invest`} />

                <meta property="og:title" content="Algotrade - Invest" />
                <meta property="og:description" content="Want to rent our trading robots, find out more." />
                <meta property="og:url" content={`https://algotrade.org/invest`} />

                <meta name="twitter:title" content="Algotrade - Invest" />
                <meta name="twitter:description" content="Want to rent our trading robots, find out more." />

                {/* <meta property="og:image" content={this.state.article.featuredImage.formats.medium.url} />
                <meta name="twitter:image" content={this.state.article.featuredImage.formats.medium.url} /> */}

            </Helmet>
            <div className="app-header charts-header">
                <h1>Invest</h1>
            </div>

            <div className="container">
                <div>
                    <h1>Rent out trading robots</h1>
                    <p>We rent out our trading robots to the public our fees are as follows:</p>
                    <ul>
                        <li>1% Flat fee (billed at 6 month intervals)</li>
                        <li>15% Performance fee (billed before the start of the next cycle)</li>
                    </ul>
                    <Link to="/ask-a-question" className="cta-button" >Get Started</Link>
                </div>

                {/* <div>
                    <h1>Invest in Algotrade AS</h1>
                    <p>Algotrade is a Private shareholder company. Meaning it is possible to purchase shares in Algotrade AS.</p>
                    <p>If you are a venture capitalist and are intrested in buying shares in Algotrade we are happy to hear from you.</p>
                    <p>Please use the contact form on <Link to="/ask-a-question">Our contact page</Link> or email makeusrich@algotrade.org</p>
                </div> */}

            </div>
            <Route path="/invest/get-started">
                <div className="container full" ref={getStarted}>

                    <p>You can use the settings below to workout your required miniumum capital based on your exposure preferences</p>


                    <div className="exposure-range chart-slider-container">
                        <label>Set your risk level: {parseFloat(exposure).toFixed(1)}%</label>
                        <RangeSlider value={exposure} stepSize={0.1} postValue="nok" min={0.1} max={2.0} onUpdate={(value) => setExposure(value)} />
                        {exposure === 1 && <p className="small-print">* Algotrade reference portefolio risk level</p>}
                        <p className="small-print">* This is the amount of drawdown you are prepared to tolerate </p>
                        <p className="small-print">* This figure is based on previous results, there is no way reliable way to predict the future and therefor this figure is an estimate of what could happen to an active portfolio. </p>
                    </div>


                    <p>Current Minimum investment based on {exposure}% exposure - {formatMoney(minCapital)} nok</p>

                    <div className="capital-range chart-slider-container">
                        <label>Set your investment capital: {formatMoney(parseInt(capital))} nok</label>
                        <RangeSlider value={capital} stepSize={50000} postValue="nok" min={minCapital} max={maxCapital} onUpdate={(value) => setCapital(value)} />
                    </div>




                    <div>
                        <p>Based on your choices and the last 6 months of Algotrade's performance your Fees would be as follows</p>
                        <ul className="fee-summery">
                            <li>Profit for last 6 months: {percentProfit.toFixed(2)}% ({formatMoney(predictedProfit)} nok) </li>
                            <li>1% Flat fee: {formatMoney(capital * 0.01)} nok</li>
                            <li>15% Performace fee: {formatMoney(parseInt(predictedProfit * 0.15))} nok</li>
                        </ul>



                    </div>

                    <div className="container full">
                        <h1>Send in your application</h1>
                        <Form form={form} updateForm={updateForm} url={formUrl} hiddenFields={{ capital, exposure }} />
                    </div>
                </div>
            </Route>




        </div>
    )
}