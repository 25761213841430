import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { parsePrice } from '../../services/helpers';

dayjs.extend(customParseFormat)


export default function AnualisedProfit({ filteredData, capital }) {
    if (!filteredData || filteredData.length === 0) {
        return null;
    }
    let total = filteredData[filteredData.length - 1].runningCapital - filteredData[0].runningCapital;
    const startDate = filteredData[0]?.date;
    const endDate = filteredData[filteredData.length - 1]?.date;
    const numberOfDays = endDate.diff(startDate, 'days');

    // const anualProfit = (netProfit / daysThisYear) * totalDays;
    const anualProfit = (total / numberOfDays) * 365;
    const netProfit = ((((capital + anualProfit) / capital) * 100) - 100)


    return (
        <div className="chart-box net-profit bordered">
            <span className="number">{netProfit.toFixed(1)}%</span>
            <span>Anualised Profit</span>
        </div>
    )
}