import React, { useState } from 'react';
import { profiles } from '../../components/profiles/profiles';
import Helmet from 'react-helmet';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
export const values = [

    {
        title: "Performance",
        content: "Stable trading performance is the core of our business. Our performance is measured purely on our ability to minimize drawdown, at the same time delivering sound profits.",
        key: "val-1",
        backgroundColor: '#12212c'

    },
    {
        title: "Passion",
        content: "Passion for mathematics, statistics and computer coding is at the heart of our company. We are continuously moving forward, innovating, and improving.",
        backgroundColor: '#12212c',
        key: "val-2"
    }, {
        title: "Integrity",
        content: "We are open, honest and fair. We will never present historical backtest performance data, without comparing it with our actual live trading performance.",
        backgroundColor: '#12212c',
        key: "val-3"
    }, {
        title: "Transparency",
        content: "Our reference live trading portfolio is sacred. The actual live performance of this portfolio is always visible in real time on our webpage – both during great runups and in painful drawdowns.",
        backgroundColor: '#12212c',
        key: "val-4"
    }, {
        title: "Diversity",
        content: "We believe that in order to survive changing market conditions, portfolio diversity is the single most important key value.",
        backgroundColor: '#12212c',
        key: "val-5"
    }
];

const productItems = [
    {
        title: "Easy",
        content: "We strive to make our using our product as streamlined as possible. We install the trading robots for you via remote session. So you can just sit back and relax while we do the work.",
        key: "prod-1",
        backgroundColor: '#2e5976'

    },
    {
        content: "No hardware to run. The automated trading systems are uploaded to a cloud server and runs seamless on these, free of charge.",
        title: "Requires",
        key: "prod-2",
        backgroundColor: '#2e5976'

    },
    {
        content: "Push and/or email notifications on all trading events. Keep track of your trades in real time from your pocket",
        title: "Delivers",
        key: "prod-3",
        backgroundColor: '#2e5976'

    },
    {
        content: "A daily account summary report. See how your trading account develops from day to day with a personalise email report.",
        title: "Creates",
        key: "prod-4",
        backgroundColor: '#2e5976'

    }, {
        content: "A professional broker. The automated trading systems runs on the IG platform, one of the worlds largest online broker companies since 1974.",
        title: "Uses",
        key: "prod-5",
        backgroundColor: '#2e5976'

    },
    {
        content: "The customer complete control over their assets. All customers may at any time start/stop the trading systems, withdraw cash or make additional deposits. Algotrade will never have access to or influence over your IG trading account.",
        title: "Gives",
        key: "prod-6",
        backgroundColor: '#2e5976'

    }
];

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function Values({ index }) {
    const item = values[index];
    if (!item) {
        return null;
    }
    return (<article>
        <h3>{item.title}</h3>
        <p>{item.text}</p>
    </article>
    )
}

export const categories = [
    {
        title: "Values",
        items: values,

    },
    {
        title: "Product",
        items: productItems,

    },
    {
        title: "Team",
        items: profiles.map(item => {
            return {
                title: "",
                content: <>
                    {<h2>{item.name}</h2>}
                    {<p>{item.description}</p>}
                </>,
                key: item.key,
                img: item.image
            }
        }),

    }
]

export default function About() {
    // const [items, setItems] = useState([...productItems, ...values].map(item => {
    //     item.size = (mediaQueryContext.query === 'mobile' ? getRandomArbitrary(75, 250) : getRandomArbitrary(100, 350)) + 'px'
    //     item.open = false;
    //     return item;
    // }).sort(() => Math.random().toFixed(0)));
    const [category, setCategory] = useState(0);
    const [item, setItem] = useState(0);
    const currentItem = categories[category].items[item];
    return (
        <div className="about">
            <Helmet>
                <title>Algotrade - Get to know us</title>
                <link rel="canonical" href={`https://algotrade.org/about`} />

                <meta property="og:title" content="Algotrade - About" />
                <meta property="og:description" content="Our philosophy, core values and team" />
                <meta property="og:url" content={`https://algotrade.org/about`} />

                <meta name="twitter:title" content="Algotrade - About" />
                <meta name="twitter:description" content="Our philosophy, core values and team" />

                {/* <meta property="og:image" content={this.state.article.featuredImage.formats.medium.url} />
                <meta name="twitter:image" content={this.state.article.featuredImage.formats.medium.url} /> */}

            </Helmet>
            <div className="app-header charts-header">
                <h1>Get to know us</h1>
            </div>

            <div className="container" style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '4em' }}>Vision</h2>
                <h2>Our vision is to make robust and profitable automated trading systems available to everyone.</h2>
                <h3>We are a company with passion and dedication for development and application of trading algorithms. We publish our results here at algotrade.org and provide the option for our members to gain access to our trading algorithms for personal use.</h3>
            </div>

            <section className="container">
                <div className="about-circles">
                    <ul>
                        {categories.map((cat, index) => <li className={index === category ? "active" : null} key={cat.key} onClick={() => {
                            setItem(0);
                            setCategory(index);

                        }}>{cat.title}</li>)}
                    </ul>
                    <div className="circle circle-1">

                        <SwitchTransition mode="out-in" >
                            <CSSTransition key={currentItem.key + '-transition'} classNames="fade" addEndListener={(node, done) => {
                                node.addEventListener("transitionend", done, false);
                            }}>
                                <div className="circle-content" >
                                    {currentItem.content}
                                </div>

                            </CSSTransition>

                        </SwitchTransition>
                        <div className="nav-circles">
                            {categories[category].items.map((dot, index) => <div onClick={() => setItem(index)} className={"nav-circle" + (item === index ? " active" : "")}></div>)}

                        </div>
                    </div>
                    <div className="circle circle-2" onClick={() => setItem(item >= categories[category].items.length - 1 ? 0 : item + 1)} style={currentItem.img ? { backgroundImage: `url(${currentItem.img})` } : currentItem.backgroundColor ? { color: 'white', backgroundColor: currentItem.backgroundColor } : null}>

                        <SwitchTransition mode="out-in" >
                            <CSSTransition key={currentItem.key + '-transition'} classNames="rotate" addEndListener={(node, done) => {
                                node.addEventListener("transitionend", done, false);
                            }}>
                                <div className="circle-content" >
                                    {currentItem.title}
                                    {category !== 2 && <FontAwesomeIcon icon={faArrowRight} />}

                                </div>

                            </CSSTransition>

                        </SwitchTransition>

                    </div>
                </div>

            </section>

            {/* <section className="container" >
                <div className="bubbles">

                    {items.map((item, index) => {
                        return (<div onClick={() => {
                            item.open = !item.open;
                            setItems(items.map((i, w) => w === index ? item : i))
                        }} className={"bubble" + (item.open ? ' open' : '')} style={{ height: item.size, width: item.size }}>
                            <div className="bubble-content">

                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>

            </section> */}

            {/* <section className="container" >
                <h2>Values</h2>
                {values.map((item) => {
                    return (<article>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                    </article>
                    );
                })}
            </section>
            <section className="container" >
                <h2>Our Product</h2>

                {[...productItems, ...values].sort(() => Math.random().toFixed(0)).map((item) => {
                    return (<article>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                    </article>
                    );
                })}
            </section> */}

            {/* 
            <Scroller title="Values" values={values} height={400} />
            <Scroller title="Our Product" values={productItems} height={600} />
 */}

            {/* <Profiles /> */}
        </div>
    );
}