import React from 'react';
import Logo from '../images/Algo1.png'
export default function LoadingLogo({ containerColor, dotColor, size }) {

    return (
        <div className="loading" >
            <div className="logo-roller">
                <img alt="Algotrade Logo Spinning" src={Logo} />
            </div>
        </div>
    );

}
