import { Bar } from 'react-chartjs-2';
import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { parsePrice } from '../../services/helpers';

dayjs.extend(quarterOfYear)
dayjs.extend(customParseFormat)


const unitIndex = {
    month: 'Monthly',
    day: 'Daily',
    quarter: 'Quarterly',
    week: 'Weekly'
}

export default function ProfitAndLossBar({ historicData, capital, exposure, unit }) {
    let data = {};
    let myColors = [];
    let startCapital = capital;
    historicData.forEach((transaction) => {
        let pandL = parsePrice(transaction.profitAndLoss);
        let date = transaction.date
        data[date.startOf(unit)] !== undefined ? data[date.startOf(unit)].y += pandL :
            data[date.startOf(unit)] = { y: pandL, x: date.startOf(unit) };
    });

    let sortedData = Object.values(data).sort((a, b) => a.x.diff(b.x));

    let chartData = sortedData.map((point) => {
        let newValue = parseFloat(((point.y / startCapital) * 100).toFixed(2));

        startCapital += parseFloat(point.y);
        point.y = newValue;
        point.x = point.x.toDate()

        point.backgroundColor = 'red'
        return point;
    });

    chartData.forEach(point => {
        if (point.y >= 0) {
            myColors.push('green');
        } else {
            myColors.push('red');
        }
    })


    const datasets = {

        datasets: [{
            label: 'Algotrade AS - ' + unitIndex[unit],
            data: chartData,
            //borderColor: 'rgb(47, 63, 85)',
            backgroundColor: myColors,

        }]
    }

    const options = {

        legend: {
            labels: {
                fontColor: "white",
                fontSize: 18
            }
        },
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: unit,
                    round: unit,
                    displayFormats: {
                        day: 'MMM D'
                    }
                },
                distribution: 'linear',
                bounds: 'data',
                ticks: {
                    fontColor: "white", // this here
                    source: 'auto'
                },
            }],
            yAxes: [{
                ticks: {
                    fontColor: "white", // this here
                    callback: function (value, index, values) {
                        return value + '%';
                    }
                },
            }],

        }
    }

    return (
        <div className="pl-bar bordered">
            <Bar data={datasets} options={options} />

        </div>
    )
}