import dayjs from 'dayjs';
import React from 'react';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { parsePrice } from '../../services/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import Collapse from '../accordian/collapse';
dayjs.extend(isSameOrAfter)

export const epicIndex = {
    "IX.D.NASDAQ.IFE.IP": "NASDAQ",
    "CS.D.AUDUSD.MINI.IP": "AUS/USD",
    "CS.D.EURUSD.MINI.IP": "EUR/USD",
    "IX.D.FTSE.IFE.IP": "FTSE",
    "IX.D.DAX.IFMM.IP": "DAX",
    "CS.D.GBPUSD.MINI.IP": "GBP/USD",
    "IX.D.DOW.IFE.IP": "DOW",
    "CS.D.USDJPY.MINI.IP": "USD/JPY",
    "IX.D.CAC.IFM.IP": "FR40",
    "IX.D.CAC.IMF.IP": "FR40",
    "IX.D.SPTRD.IFE.IP": "US500",
    "CS.D.EURJPY.MINI.IP": "EUR/JPY",
    "CS.D.GBPJPY.MINI.IP": "GBP/JPY",
    "CS.D.USDCAD.MINI.IP": "USD/CAD",
    "CC.D.CL.UME.IP": "US CRUDE",
    "CS.D.AUDJPY.MINI.IP" : "AUD/JPY"
}

export function tradeName(name) {
    if (name.toLowerCase().includes("germany")) {
        return "DAX"
    }

    if (name.toLowerCase().includes("wall street")) {
        return "DOW"
    }
    if (name.toLowerCase().includes("gbp/usd")) {
        return "GBP/USD"
    }
    if (name.toLowerCase().includes("aud/usd")) {
        return "AUD/USD"
    }
    if (name.toLowerCase().includes("eur/usd")) {
        return "EUR/USD"
    }
    if (name.toLowerCase().includes("gbp/usd")) {
        return "GBP/USD"
    }
    if (name.toLowerCase().includes("usd/jpy")) {
        return "USD/JPY"
    }
    if (name.toLowerCase().includes("aud/jpy")) {
        return "AUD/JPY"
    }
    if (name.toLowerCase().includes("gbp/jpy")) {
        return "GBP/JPY"
    }
    if (name.toLowerCase().includes("eur/jpy")) {
        return "EUR/JPY"
    }
    if (name.toLowerCase().includes("usd/cad")) {
        return "USD/CAD"
    }
    if (name.toLowerCase().includes("tech")) {
        return "NASDAQ"
    }
    if (name.toLowerCase().includes("us 500")) {
        return "US500"
    }
    if (name.toLowerCase().includes("ftse")) {
        return "FTSE"
    }
    if (name.toLowerCase().includes("france")) {
        return "FR40"
    }
    if (name.toLowerCase().includes("crud")) {
        return "CRUD"
    }
}


export default function ClosedTrades({ historicData, callUpdate }) {
    const todaysTrades = historicData?.filter(trade => trade.transactionType === "HANDEL" && trade.date.isSameOrAfter(dayjs().startOf('day')));

    if (todaysTrades?.length === 0) {
        return null;
    }
    const capitalOnStartOfToday = todaysTrades[0].runningCapital - parsePrice(todaysTrades[0].profitAndLoss);
    const capitalNow = todaysTrades[todaysTrades.length - 1].runningCapital;
    let todaysTotal = ((capitalNow - capitalOnStartOfToday) / capitalOnStartOfToday) * 100;

    let agregated = Object.values(todaysTrades).reduce((result, item) => {
        item.changeFromOpenPercent = parsePrice(item.profitAndLoss) / (item.runningCapital - parsePrice(item.profitAndLoss))

        if (!result[tradeName(item.instrumentName)]) {
            result[tradeName(item.instrumentName)] = { items: [item], ...item };
        } else {
            const prevPerc = 1 + result[tradeName(item.instrumentName)].changeFromOpenPercent;
            const nextPerc = 1 + item.changeFromOpenPercent;
            const res = prevPerc * nextPerc;
            result[tradeName(item.instrumentName)].changeFromOpenPercent = res - 1;
            result[tradeName(item.instrumentName)].items.push(item);
        }
        return result;
    }, {})


    return (<div className="chart-box closed-trades bordered">

        <h4>Closed Trades (today)
            {/* <small style={{ marginLeft: '1em', cursor: 'pointer' }}>
            <FontAwesomeIcon onClick={() => callUpdate()} icon={faSync} />
            </small>  */}
        </h4>

        {agregated !== {} &&
            <ul className="live">
                {Object.values(agregated).map((value) => {
                    if (!value)
                        return null;
                    //todaysTotal += value.changeFromOpenPercent;
                    const changeFromOpenPercent = value.changeFromOpenPercent * 100;
                    let className = changeFromOpenPercent.toFixed(2) >= 0 ? 'green' : 'red';
                    const title = <>
                        <span> {value.items.length > 1 && <FontAwesomeIcon icon={faListOl} />} {epicIndex[tradeName(value.instrumentName)] || tradeName(value.instrumentName)}</span>
                        <span className={className}> {changeFromOpenPercent.toFixed(2)}%</span>
                    </>;

                    return (<li key={'live-' + tradeName(value.instrumentName)}>
                        {value.items.length > 1 ?
                            <Collapse title={title}
                                hideToggle={true}
                                content={() => {

                                    return (<ul>
                                        {value.items.map((subItem, index) => {
                                            let subPercent = subItem.changeFromOpenPercent * 100;
                                            let className = subPercent?.toFixed(2) >= 0 ? 'green' : 'red';

                                            return (<li><span>{index + 1}</span><span className={className}>{subPercent?.toFixed(2)}%</span></li>)
                                        })}

                                    </ul>)
                                }
                                }
                            />
                            : title
                        }



                    </li>)
                })
                }

                <li className="line-above" style={{ flex: '1 0 100%' }}>
                    {Object.values(agregated).length === 0 ?

                        <span>There is currently no live trades</span>
                        :
                        <>

                            <span>TOTAL</span>
                            <span className={todaysTotal.toFixed(2) >= 0 ? 'green' : 'red'}>{todaysTotal.toFixed(2)}%</span>
                        </>}
                </li>
            </ul>
        }
        {
            (!todaysTrades || todaysTrades.length === 0) && <p>We haven't closed any trades today.</p>
        }

    </div>)
}