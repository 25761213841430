import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)


export default function NumberOfTrades({ historicData }) {

    const total = historicData.filter(tran => tran.transactionType === 'HANDEL').length;

    return (
        <div className="chart-box net-profit bordered">
            <span className="number">{total}</span>
            <span>No. Trades</span>
        </div>
    )
}