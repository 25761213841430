import React, { useEffect } from 'react';
import './App.scss';
import Home from './pages/home/home';
import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import MainSidebar, { menuItems } from './components/sidebar/mainSidebar';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])
  return (
    <div className="App">
      <MainSidebar />
      <div className="app-content">


        <Switch>
          <Route key="home" path="/" exact={true} component={Home}></Route>

          {menuItems.map(item => <Route key={item.key} path={item.to} exact={true} component={item.component}></Route>)}
        </Switch>


        <footer>
          <div className="container">
            <h1>Algotrade AS</h1>
            <h4>INVESTING/TRADING IN CFD PRODUCTS AS IG.COM INVOLVES SUBSTANTIAL RISK</h4>
            <a href="mailto:post@algotrade.org">post@algotrade.org</a>
            <address>
              Sjølystveien 50, 4610, Kristiansand
            </address>
            <p>Org no: 923 822 054</p>
            <p>LEI: 984500E607F63C2A8A15</p>



            {/* <div className="cookies-container">
                <p>Dette nettstedet benytter informasjonskapsler (cookies). Les mer her | Ikke vis denne meldingen igjen</p>
              </div> */}
          </div>
        </footer>
      </div>



    </div>
  );
}

export default App;
