import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { parsePrice } from '../../services/helpers';

dayjs.extend(customParseFormat)


export default function Winrate({ historicData }) {
    let wins = 0
    let losses = 0;
    historicData.filter(tran => tran.transactionType === 'HANDEL').forEach(trade => {
        const tradeResult = parsePrice(trade.profitAndLoss);
        if (tradeResult >= 0) {
            wins++;
        } else {
            losses++;
        }


    });
    const total = wins + losses;

    return (
        <div className="chart-box net-profit bordered">
            <span className="number">{(total === 0 ? total : wins / total * 100).toFixed(1)}%</span>
            <span>Winrate</span>
        </div>
    )
}