import React, { useEffect, useState } from 'react'
import Collapse from './collapse';
const categorise = (items) => {
    return items.reduce((result, item) => {
        if (!item.Category) {
            result.other ? result.other.items.push(item) : result.other = { title: item.Category, items: [item], key: uuidv4() };
            return result;
        }

        result[item.Category] ? result[item.Category].items.push(item) : result[item.Category] = { title: item.Category, items: [item], key: uuidv4() };
        return result;
    }, [])
}
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;
        var v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}


export default function Accordian({ items }) {
    const [toggled, setToggled] = useState();
    const [categories, setCategories] = useState(categorise(items))
    useEffect(() => {
        let keys = {};
        items.forEach(({ id }) => {
            keys[id] = false;
        });
        setToggled(keys)
    }, [items])

    useEffect(() => {
        setCategories(categorise(items));
    }, [items])

    const toggle = (id) => {
        let nextToggled = { ...toggled };
        nextToggled[id] = !nextToggled[id];
        setToggled(nextToggled);
    };

    return (
        <>
            {Object.values(categories).map(cat => <div key={cat.key}>
                <h3>{cat.title}</h3>
                {cat.items.map((item) => <Collapse key={item.key} toggle={toggle} toggled={toggled && item.id && toggled[item.id]} {...item}></Collapse>)}
            </div>
            )}
        </>
    );

}