import React from 'react';
import Charts from '../../components/charts/charts';
import Helmet from 'react-helmet';
export default function Home() {
    return (<div className="home">
        <Helmet>
            <title>Algotrade</title>
            <link rel="canonical" href={`https://algotrade.org/`} />

            <meta property="og:title" content="Algotrade" />
            <meta property="og:description" content="Automated trading robots for every day people" />
            <meta property="og:url" content={`https://algotrade.org/`} />

            <meta name="twitter:title" content="Algotrade" />
            <meta name="twitter:description" content="Automated trading robots for every day people" />

            {/* <meta property="og:image" content={this.state.article.featuredImage.formats.medium.url} />
                <meta name="twitter:image" content={this.state.article.featuredImage.formats.medium.url} /> */}

        </Helmet>

        <div className="app-header charts-header">
            <h1>Dashboard</h1>
        </div>

        <Charts />
    </div>);
}