import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { parsePrice } from '../../services/helpers';

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore);

export default function MonthByMonth({ historicData, onMonthClick, onYearClick }) {
    const start = dayjs().year(2020).startOf('year');
    const end = dayjs().endOf('year')
    let months = {};
    // Need to get start capital for ths start of the year.
    let startCapital = historicData[0].runningCapital - historicData[0].profitAndLoss;
    let loopStart = dayjs(start);
    while (loopStart.isBefore(end)) {

        const key = loopStart.year() + '-' + loopStart.month();
        months[key] = { value: 0, date: dayjs(loopStart).endOf('month') };
        loopStart = loopStart.add(1, 'month');

    }

    historicData.forEach(trade => {
        const tradeResult = parsePrice(trade.profitAndLoss);
        const key = trade.date.year() + '-' + trade.date.month();
        months[key].value += tradeResult;

    });


    let monthsIndex = [];
    let indexStart = dayjs().startOf('year');
    while (indexStart.isBefore(end)) {
        monthsIndex.push(indexStart);
        indexStart = indexStart.add(1, 'month');
    }

    let firstTwTotal = 100
    return (
        <div className="month-by-month bordered">
            <div className="table-heading">P/L</div>
            {monthsIndex.map(month => <div className="table-heading">{month.format('MMM')}</div>)}
            <div className="table-heading">
                Total
            </div>

            {Object.entries(months).map(([key, { date, value }], index) => {
                let result = [];
                const isEndOfYear = index % 12 === 11;
                const isStartOfYear = index % 12 === 0;
                if (isStartOfYear) {
                    firstTwTotal = 100
                }
                const capitalStartOfMonth = startCapital;

                const percentChange = (!value) ? 0 : (value / capitalStartOfMonth) * 100;
                const style = {
                    color: percentChange >= 0 ? 'green' : 'red'
                }

                startCapital += value;
                firstTwTotal *= (percentChange + 100) / 100;

                if (isStartOfYear) {
                    result.push(<div className="table-heading year-index">
                        {date.format('YYYY')}
                    </div>);
                }

                if (percentChange === 0) {
                    result.push(<div />);
                } else {

                    result.push(<div className="month" style={style} onClick={() => onMonthClick(key)}>
                        <span>{percentChange.toFixed(2)}%</span>
                    </div>);

                }

                if (isEndOfYear) {

                    result.push(<div className="month total"
                        onClick={() => onYearClick(key)}

                        style={{
                            color: firstTwTotal - 100 >= 0 ? 'green' : 'red',
                            fontWeight: 'bold'
                        }}>
                        {<span>{(firstTwTotal - 100).toFixed(2)}%</span>}
                    </div>);

                }

                return <>{result}</>

            })}



            {/* {Object.entries(months).slice(12, 24).map(([key, value], index) => {
                let percentChange = (value / startCapital) * 100;
                startCapital += value;
                console.log("capital on " + dayjs(key).format(), startCapital)
                secondTwTotal += percentChange;
                const style = {
                    color: percentChange >= 0 ? 'green' : 'red'
                }
                if (index === 11) {
                    return <>
                        <div className="month" style={style}>
                            {dayjs(key).isBefore(dayjs()) && <span>{percentChange.toFixed(2)}%</span>}
                        </div>
                        <div className="month total" style={{
                            color: secondTwTotal >= 0 ? 'green' : 'red'
                        }}>
                            {<span>{secondTwTotal.toFixed(2)}%</span>}
                        </div>
                    </>
                }

                return (<div className="month" style={style}>
                    {dayjs(key).isBefore(dayjs()) && <span>{percentChange.toFixed(2)}%</span>}
                </div>)
            })} */}

        </div>
    )
}