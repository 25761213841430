import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';

export default function Collapse({ title, toggle, toggled, content, key, id, toggleOnLeft, hideToggle }) {
    let className = 'collapse';

    const toggleMarkup = toggled ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />
    let setToggled, isToggled;
    const [selfToggled, setSelfToggled] = useState(false);

    if (typeof toggle !== 'function') {
        setToggled = () => setSelfToggled(!selfToggled);
        isToggled = selfToggled;
    } else {
        setToggled = toggle;
        isToggled = toggled;
    }

    if (isToggled) {
        className += ' toggled';
    }

    const ToggledContent = content;
    return (
        <div className={className}>
            <div className="header" onClick={() => setToggled(id)}>
                {toggleOnLeft && !hideToggle && <span>{toggleMarkup}</span>}
                {hideToggle ? title : <span>{title}</span>}
                {!toggleOnLeft && !hideToggle && <span>{toggleMarkup}</span>}
            </div>
            <div className="content">
                {typeof ToggledContent === 'function' ? <ToggledContent /> : <ReactMarkdown>{ToggledContent}</ReactMarkdown>}
            </div>
        </div>
    );

}