import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Info from '../info';
import { Drawdown } from './maxDrawdown';

dayjs.extend(customParseFormat)


export default function NPDD({ historicData, capital }) {
    if (!historicData || historicData.length === 0) {
        return null;
    }
    const { maxDrawdown, total } = Drawdown(capital, historicData);
    return (
        <div className="chart-box net-profit bordered">
            <span className="number">{capital ? ((((total / capital) - 1) * 100) / (maxDrawdown)).toFixed(2) : 0}</span>
            <span>NP/DD</span>
            <Info>

                <h3>(Net profit / max drawdown) </h3>
                <p>is the ratio between the profits of the trading system and the maximum drawdown of that system </p>
                <p>Read more <a href="https://www.investopedia.com/terms/r/return-over-maximum-drawdown-romad.asp">here</a></p>
            </Info>
        </div>
    )
}