import React, { useContext, useEffect, useState } from 'react';
import Logo from '../../images/Algo1.png';
import Sidebar from './sidebar.js';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { faEnvelope, faUserTie, faWallet, faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Faq from '../../pages/faq/faq';
import { MediaQueryContext } from '../../services/mediaQueryContext';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import About from '../../pages/about/about';
import Invest from '../../pages/invest/invest';
import Contact from '../../pages/contact/contact';

export const menuItems = [{
    to: "/get-to-know-us",
    icon: <FontAwesomeIcon alt="Get to know us" icon={faUserTie} />,
    label: <span>G</span>,
    key: "lg",
    hoverText: "Get to know us",
    component: About
}, {
    to: "/ask-a-question",
    icon: <FontAwesomeIcon alt="Ask a question" icon={faEnvelope} />,
    label: <span>A</span>,
    key: "la",
    hoverText: "Ask a question",
    component: Contact

},
{
    to: "/invest",
    icon: <FontAwesomeIcon alt="Invest" icon={faWallet} />,
    label: <span>I</span>,
    key: "li",
    hoverText: "Invest",
    component: Invest
}, {
    to: "/nice-to-know",
    icon: <FontAwesomeIcon alt="Nice to know" icon={faCommentsDollar} />,
    label: <span>N</span>,
    key: "ln",
    hoverText: "Nice to know",
    component: Faq
}]

export default function MainSidebar() {
    const [scrolled, setScrolled] = useState(0);
    const [hovering, setHovering] = useState();
    const { query } = useContext(MediaQueryContext);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrolled(window.scrollY);
        });
    }, [])

    const imageStyle = {
        transform: `rotate(${scrolled / 4}deg)`
    }

    const location = useLocation();

    return (
        <Sidebar>
            {query !== 'mobile' && <Link className="logo-container" to="/"><img style={imageStyle} alt="Algotrade Logo" className="main-logo" src={Logo} /></Link>}
            <ul>
                {menuItems.map((item, index) => {

                    return (
                        <li style={{ order: index * 2 }} onClick={() => setHovering(index)} key={item.key} onMouseLeave={() => query === 'desktop' && setHovering(null)} onMouseEnter={() => setHovering(index)} className="hoverable-link">
                            <NavLink activeClassName="active" to={item.to}><div className="circle-icon">
                                <SwitchTransition mode="out-in">
                                    <CSSTransition key={"tr" + item.key + (hovering === index || location.pathname === item.to).toString()} in={hovering === index} timeout={300} classNames="circle-rotate" mountOnEnter unmountOnExit addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                    >
                                        {hovering === index || location.pathname === item.to ? item.label : item.icon}
                                    </CSSTransition>

                                </SwitchTransition>
                            </div></NavLink>
                            <CSSTransition in={query === 'desktop' && hovering === index} timeout={300} classNames="hovering" mountOnEnter unmountOnExit>
                                <span className="link-text">{item.hoverText}</span>
                            </CSSTransition>
                        </li>);
                }
                )}

                {query === 'mobile' && <li style={{ order: 3 }}> <Link className="logo-container" to="/"><img style={imageStyle} alt="Algotrade logo" className="main-logo" src={Logo} /></Link></li>}


            </ul>
        </Sidebar>
    )
}