import React from 'react';
export default function RangeSlider({ min, max, onUpdate, stepSize, value }) {

    const onChange = (e) => {
        if (typeof onUpdate === 'function') {
            onUpdate(e.target.value);
        }
    }
    return (
        <div className="range-slider-container">
            <input type="range" min={min} max={max} value={value} onChange={onChange} className="range-slider" step={stepSize || 1} />
        </div>
    )
}
