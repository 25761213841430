import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Info from '../info';
import { Drawdown } from './maxDrawdown';
import ddimg from '../../images/current-drawdown.png';
dayjs.extend(customParseFormat)


export default function CurrentDrawdown({ historicData, capital }) {
    if (!historicData || historicData.length === 0) {
        return null;
    }
    let { currentDrawdown } = Drawdown(capital, historicData);
    currentDrawdown = Math.max(0, currentDrawdown)
    return (
        <div className="chart-box net-profit bordered">
            <span className="number">{currentDrawdown.toFixed(2)}%</span>
            <span>Current Drawdown </span>
            <Info>
                <h3>Current Drawdown</h3>
                <p>is the % difference between the all time high and the current equity curve level.</p>
                <img src={ddimg} alt="chart showing current drawdown" />
            </Info>
        </div>
    )
}